export function addPaddingToScrollableContent() {
      let scrollableContent =
        document.getElementsByClassName('scrollable-content')[0]
        if (scrollableContent) {
      if (isOverflowing(scrollableContent)) {
        scrollableContent.style.setProperty("padding-right", "1em", "important");
      } else {
        scrollableContent.style.setProperty("padding-right", "0", "important");
      }
    }
  }
  
  function isOverflowing({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
    return scrollHeight > clientHeight || scrollWidth > clientWidth
  }